import Vue from "vue";
import Router from "vue-router";
// import store from "./store";
// import axios from "axios";
Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [{
            path: "/",
            props: true,
            component: () =>
                import ("./views/home"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/WTIDonationForm",

            name: "WTIDonationForm",
            props: true,
            component: () =>
                import ("./views/home2"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/success-stories",
            name: "rehabitation",
            props: true,
            component: () =>
                import ("./views/rehabitation"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/DonationForm",
            name: "DonationForm",
            props: true,
            component: () =>
                import ("./donations/conservationHeroes"),
            meta: {
                requiresAuth: false,
            },
        },
        
        {
            path: "/refundAndCancellation",
            name: "refundAndCancellation",
            props: true,
            component: () =>
                import ("./components/Policy/refundAndCancellation"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/privacyPolicy",
            name: "privacyPolicy",
            props: true,
            component: () =>
                import ("./components/Policy/privacyPolicy"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "/termsAndCondition",
            name: "termsAndCondition",
            props: true,
            component: () =>
                import ("./components/Policy/termsAndCondition"),
            meta: {
                requiresAuth: false,
            },
        },

        //payment routes
        {
            path: '/payment',
            props: true,
            name: 'payment',
            component: () =>
                import ('./Payment/payment'),
        },
        {
            path: '/success',
            props: true,
            name: 'success',
            component: () =>
                import ('./Payment/success'),
        },
        {
            path: '/failure',
            props: true,
            name: 'failure',
            component: () =>
                import ('./Payment/failure'),
        },
        {
            path: '/error',
            props: true,
            name: 'failure',
            component: () =>
                import ('./Payment/ErrorPage'),
        },




        // end of payment routes

        {
            path: "/ServerError",
            name: "ServerError",
            props: true,
            component: () =>
                import ("./components/Common/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404PageNotFound",
            props: true,
            component: () =>
                import ("./components/Common/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});
// router.beforeEach((to, from, next) => {
//   if (to.fullPath !== "/Login") {
//     localStorage.setItem("routeKey", to.fullPath);
//   }
//   if (
//     store.state.isLoggedIn == true ||
//     to.matched.some((route) => route.meta.requiresAuth == true)
//   ) {
//     axios({
//       method: "POST",
//       url: "/authenticate/user",
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     })
//       .then((response) => {
//         if (!response.data.status) {
//           store.commit("sessionOut", true);
//           return;
//         }
//       })
//       .catch((err) => {
//         var msg = err;
//         console.log(msg);
//       });
//   }

//   if (
//     to.path == "/Seller" &&
//     store.state.isLoggedIn == true &&
//     store.state.userType == "customer"
//   ) {
//     next({ name: "Login" });
//     return;
//   }
//   if (
//     localStorage.getItem("profStat") == "active" &&
//     store.state.userType === "seller"
//   ) {
//     if (
//       to.path == "/" ||
//       (to.path == "/Login" &&
//         store.state.isLoggedIn == true &&
//         store.state.userType === "seller")
//     ) {
//       next({ name: "Seller" });
//       return;
//     }
//   }
//   if (
//     localStorage.getItem("profStat") == "incomplete" &&
//     store.state.userType === "seller"
//   ) {
//     if (
//       to.path == "/" ||
//       to.path == "/Seller" ||
//       (to.path == "/Login" &&
//         store.state.isLoggedIn == true &&
//         store.state.userType === "seller")
//     ) {
//       next({ name: "completeRegistration" });
//       return;
//     }
//   }

//   if (
//     localStorage.getItem("profStat") == "pending" &&
//     store.state.userType === "seller"
//   ) {
//     if (
//       to.path == "/" ||
//       to.path == "/Seller" ||
//       (to.path == "/Login" &&
//         store.state.isLoggedIn == true &&
//         store.state.userType === "seller")
//     ) {
//       next({ name: "completeRegistration" });
//       return;
//     }
//   }

//   if (
//     localStorage.getItem("profStat") == "reject" &&
//     store.state.userType === "seller"
//   ) {
//     if (
//       to.path == "/" ||
//       to.path == "/Seller" ||
//       (to.path == "/Login" &&
//         store.state.isLoggedIn == true &&
//         store.state.userType === "seller")
//     ) {
//       next({ name: "sellerStatus" });
//       return;
//     }
//   }


//   next();
// });

export default router;